import React from 'react'
import { graphql } from 'gatsby'
import style from './timeline.mod.scss'
import { Container, Button } from '@components'
import { GatsbyImage } from 'gatsby-plugin-image'
import Tick from '@svgs/tick.svg'
import {
  VerticalTimeline,
  VerticalTimelineElement
} from 'react-vertical-timeline-component'
import 'react-vertical-timeline-component/style.min.css'

const Timeline = ({ items }) => {
  console.log(items)

  return (
    <div className={style.timeline}>
      <Container maxWidth={'large'}>
        {items.length > 0 && (
          <VerticalTimeline>
            {items.map(item => (
              <TimelineItem
                key={`timeline-item-${item.id}`}
                heading={item.heading}
                description={item.description}
                image={item.image}
                date={item.date}
                dateOveride={item.dateOveride}
                button={item.button}
                complete={item.complete}
              />
            ))}
            <VerticalTimelineElement className={style.timeline__final} />
          </VerticalTimeline>
        )}
      </Container>
    </div>
  )
}

const TimelineItem = ({
  image,
  heading,
  date,
  dateOveride,
  description,
  button,
  complete
}) => {
  return (
    <VerticalTimelineElement
      className="vertical-timeline-element--work"
      contentStyle={{
        background: 'white',
        color: 'currentColor',
        padding: 0
      }}
      date={dateOveride || date}
      icon={complete && <Tick />}
    >
      <div className={style.timeline__item}>
        {image && (
          <div className={style.timeline__image}>
            <GatsbyImage image={image.gatsbyImageData} alt={image.alt} />
          </div>
        )}
        <div className={style.timeline__content}>
          <h4 className="vertical-timeline-element-title">{heading}</h4>
          <p>{description}</p>
          {(button?.link?.path || button?.url) && (
            <div className={style.timeline__button}>
              <Button
                buttonStyle="secondary"
                to={button?.link?.path || button?.url}
              >
                {button.displayText}
              </Button>
            </div>
          )}
        </div>
      </div>
    </VerticalTimelineElement>
  )
}

export default Timeline

export const query = graphql`
  fragment Timeline on DatoCmsTimeline {
    id
    items {
      id
      heading
      image {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { h: "270", w: "530", fit: "crop", q: 60 }
        )
        alt
      }
      description
      complete
      date(formatString: "MMMM YYYY")
      dateOveride
      button {
        ...Link
      }
    }
    paddingBottom
    paddingTop
    model {
      apiKey
    }
    __typename
  }
`
