import React, { useState } from 'react'
import { graphql } from 'gatsby'
import style from './ticker.mod.scss'
import Ticker from 'react-ticker'
import { GatsbyImage } from 'gatsby-plugin-image'

const BrandTicker = ({ images }) => {
  console.log(images)
  return (
    <div className={style.ticker}>
      <div className={style.ticker__wrap}>
        <Ticker speed={7}>
          {({ index }) => (
            <div className={style.ticker__items}>
              {images.map(item => (
                <div
                  className={style.ticker__image}
                  key={`ticker-item-${item.id}-${index}`}
                >
                  <GatsbyImage image={item.gatsbyImageData} alt={''} />
                </div>
              ))}
            </div>
          )}
        </Ticker>
        <div className={style.ticker__left} />
        <div className={style.ticker__right} />
      </div>
    </div>
  )
}

export default BrandTicker

export const query = graphql`
  fragment Ticker on DatoCmsTicker {
    id
    model {
      apiKey
    }
    paddingTop
    paddingBottom
    background
    backgroundStyle
    __typename
    images {
      gatsbyImageData(imgixParams: { w: "500" })
      id: originalId
    }
  }
`
